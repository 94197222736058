<template lang="">
  <v-row justify="center" dense>
    <v-checkbox
      v-bind="$attrs"
      v-on="$listeners"
      class="mt-0"
      hide-details="auto"
    >
      <slot slot="label"
        >Aceptar&nbsp;<a
          @click.stop
          :href="termsUrl"
          target="_blank"
          class="text-decoration-none font-weight-bold"
        >
          políticas de privacidad </a
        >.</slot
      >
    </v-checkbox>
  </v-row>
</template>

<script>
export default {
  computed: {
    termsUrl() {
      return process.env.VUE_APP_TERMS_URL;
    },
  },
};
</script>
