<template>
  <base-section id="reserve" space="0">
    <v-container class="fill-height px-4 py-12">
      <v-row justify="center">
        <v-col class="pb-6" cols="11" sm="8" xl="6">
          <available-shifts
            v-if="
              !shiftDetails.date ||
              !shiftDetails.qtyPerson ||
              !shiftDetails.shift
            "
            @update:shiftDetails="shiftDetails = $event"
          />
          <submit-booking v-else :shift-details="shiftDetails" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import AvailableShifts from "@/components/reservation/available-shifts/Index";
import SubmitBooking from "@/components/reservation/submit-booking/Index";

export default {
  name: "ReservationSection",

  provide: {
    heading: { align: "center" },
  },

  components: {
    AvailableShifts,
    SubmitBooking,
  },

  data: () => {
    return {
      shiftDetails: {
        date: null,
        qtyPerson: null,
        shift: null,
      },
    };
  },
};
</script>
