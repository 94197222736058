<template>
  <v-row justify="start">
    <v-col cols="12">
      <base-subheading :title="$t('makeReservation')" mobileSize="text-h5" />
    </v-col>

    <v-col cols="12" md="6">
      <date-picker @update:reservationDate="payload.date = $event" />
    </v-col>

    <v-col cols="12" md="6">
      <v-select
        outlined
        dense
        hide-details
        single-line
        v-model="payload.people"
        :items="rangeDinnersOptions"
        :label="$t('selectNumberOfDinners')"
        prepend-icon="mdi-account-group"
        menu-props="auto"
      />
    </v-col>

    <v-col cols="12" class="px-6">
      <v-row
        v-if="loadingContent && payload.date && payload.people"
        justify="center"
      >
        <v-progress-circular
          :width="15"
          :size="100"
          color="rgba(184, 184, 184,0.4)"
          indeterminate
        />
      </v-row>
      <v-row v-else dense>
        <hour-proposal-single
          v-for="(hourProposal, index) in availableShifts"
          :key="index"
          :id="hourProposal.id"
          :available="hourProposal.available"
          :start-time="hourProposal.start_time"
          :selected-id="selectedId"
          @update:selectedId="selectedId = $event"
        />
      </v-row>

      <OptionsReservations
        :optionsSelected="optionsSelected"
        @add:optionSelected="handleAddOptionSelected"
        @update:optionSelected="handleUpdateOptionSelected"
        @delete:optionSelected="handleDeleteOptionSelected"
      />
      <v-row class="mt-5">
        <v-col cols="12">
          <h4 class="my-3">{{ $t("anySpecialRequest") }}</h4>
          <v-textarea
            v-model="details"
            :height="100"
            :rules="rulesLetters"
            rounded
            class="pt-2"
            flat
            solo
            outlined
          />
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <v-row justify="center">
        <base-btn
          @click="
            $emit('update:shiftDetails', {
              details,
              date: payload.date,
              qtyPerson: payload.people,
              shift: selectedId,
              options: optionsSelected,
            })
          "
          :disabled="!selectedId"
        >
          {{ $t("continue") }}
        </base-btn>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { reservationsCtrl, settingCtrl } from "@/controllers";
import { rulesLetters } from "@/helpers";

export default {
  components: {
    DatePicker: () =>
      import("@/components/reservation/available-shifts/DatePicker"),
    HourProposalSingle: () =>
      import("@/components/reservation/available-shifts/HourProposalSingle"),
    OptionsReservations: () =>
      import("@/components/reservation/available-shifts/OptionsReservations"),
  },

  data: () => {
    return {
      rulesLetters,
      payload: {
        date: null,
        people: null,
      },
      selectedId: null,
      availableShifts: [],
      loadingContent: false,
      rangeDinners: { min: 0, max: 0 },
      optionsSelected: [],
      details: "",
    };
  },

  watch: {
    payload: {
      handler(newPayload) {
        this.validatePayload(newPayload);
      },
      deep: true,
    },
  },

  async mounted() {
    await this.getMinAndMaxDinners();
  },

  methods: {
    async getMinAndMaxDinners() {
      const { min_clients, max_clients } =
        await settingCtrl.getReservationSetting();
      this.rangeDinners = { min: min_clients || 1, max: max_clients || 4 };
    },
    validatePayload(payload) {
      if (payload.date && payload.people) {
        this.getContent(payload);
      } else {
        this.clearPayload();
      }
    },
    clearPayload() {
      this.selectedId = null;
      this.availableShifts = [];
    },
    getContent(payload) {
      this.loadingContent = true;
      reservationsCtrl
        .getAvailableShifts(payload)
        .then((data) => {
          this.selectedId = null;
          this.availableShifts = data;
        })
        .finally(() => {
          this.loadingContent = false;
        });
    },
    handleAddOptionSelected(optionSelected) {
      const { id, answer } = optionSelected;
      this.optionsSelected = [
        ...this.optionsSelected,
        { id, answers: [answer.id] },
      ];
    },
    handleUpdateOptionSelected(optionSelected) {
      const { id, answer, isMultiple } = optionSelected;
      this.optionsSelected = this.optionsSelected.map((opt) => {
        if (id === opt.id)
          return {
            id,
            answers: isMultiple ? [...opt.answers, answer.id] : [answer.id],
          };
        return opt;
      });
    },
    handleDeleteOptionSelected(optionSelected) {
      const { id, answer, isMultiple, deleteOption } = optionSelected;
      this.optionsSelected =
        deleteOption || !isMultiple
          ? this.optionsSelected.filter((opt) => opt.id !== id)
          : this.optionsSelected.map((opt) => {
              if (id === opt.id)
                return {
                  id,
                  answers: opt.answers.filter(
                    (answerId) => answerId !== answer.id
                  ),
                };
              return opt;
            });
    },
  },

  computed: {
    rangeDinnersOptions() {
      return Array.from(
        { length: this.rangeDinners.max - this.rangeDinners.min + 1 },
        (_, i) => i + this.rangeDinners.min
      );
    },
  },
};
</script>
